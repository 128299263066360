<template>
  <div class="column" :class="'item_' + classSuffix">
    <img v-if="data" :src="data[0] === 'd' ? data : host + data" />
  </div>
</template>

<script>
export default {
  name: "UkTableRowItemRestaurantLogo",
  props: ["data", "classSuffix"],
  data() {
    return { host: process.env.VUE_APP_SERVER_URL };
  },
};
</script>

<style scoped>
img {
  max-height: 21px;
}
</style>
