<template>
  <div class="column" :class="'item_' + classSuffix">
    <a :href="url" target="_blank">{{ url }}</a>
  </div>
</template>

<script>
export default {
  name: "UkTableRowItemUrl",
  props: ["data", "classSuffix"],
  computed: {
    url: function () {
      return process.env.VUE_APP_HOST + this.data;
    },
  },
};
</script>
