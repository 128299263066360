<template>
  <div class="column" :class="'item_' + classSuffix">
    <div class="text_rest">{{ data.name }}</div>
  </div>
</template>

<script>
export default {
  name: "UkTableRowItemRestaurant",
  props: ["data", "classSuffix"],
};
</script>
