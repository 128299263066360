<template>
  <div>
    <a-spin :spinning="loading">
      <div class="top">
        <div class="caption">{{ $route.name }}</div>
        <a
          class="add_object open-popup-link"
          href="#add_object"
          data-effect="mfp-move-horizontal"
          @click.prevent="addObject"
        >
          Добавить локацию
        </a>
      </div>
      <div class="middle">
        <div class="full_objects">
          <UkTable :columns="columns" :data="computedData" :itemsPerPage="10">
            <div class="uk-table-slot">
              <div>Локаций пока нет</div>
              <a @click.prevent="addObject">Добавить локацию</a>
            </div>
          </UkTable>
        </div>
      </div>
      <Drawer :showDrawer="showDrawer" :close="closeDrawer" :maxWidth="888">
        <EditObject :cardData="cardData" />
      </Drawer>
    </a-spin>
  </div>
</template>

<script>
import moment from "moment";
import "moment/locale/ru";
import { cloneDeep } from "lodash";

import { bus } from "@/bus";

import UkTable from "@/components/common/UkTable";
import UkTableRowItemRestaurant from "@/components/common/UkTableRowItemRestaurant";
import UkTableRowItemUrl from "@/components/common/UkTableRowItemUrl";
import UkTableRowItemDate from "@/components/common/UkTableRowItemDate";
import UkTableRowItemRestaurantLogo from "@/components/common/UkTableRowItemRestaurantLogo";
import UkTableRowItemEdit from "@/components/common/UkTableRowItemEdit";
import Drawer from "@/components/common/Drawer";
import EditObject from "@/components/common/EditObject";

import { getPoints } from "@/helpers/dataHelpers";

const columns = [
  {
    name: "Объект",
    classSuffix: "name",
    id: "restaurant",
    component: UkTableRowItemRestaurant,
    filter: true,
  },
  {
    name: "Адрес URL",
    classSuffix: "url",
    id: "url",
    component: UkTableRowItemUrl,
  },
  {
    name: "Дата создания",
    classSuffix: "date",
    id: "date",
    component: UkTableRowItemDate,
    sorter: (a, b) => a - b,
  },
  {
    name: "Логотип",
    classSuffix: "logo",
    id: "logo",
    component: UkTableRowItemRestaurantLogo,
  },
  {
    name: "",
    classSuffix: "edit",
    id: "edit",
    component: UkTableRowItemEdit,
  },
];

function mappingB2F(ob) {
  return {
    key: ob.pointId,
    restaurant: { key: ob.pointId, name: ob.name },
    url: ob.url,
    logo: ob.logo,
    date: moment.utc(ob.created_at, "YYYY-MM-DD[T]HH:mm:ss.SSSSSS[Z]").local(),
  };
}

export default {
  name: "ListOfObjects",
  data() {
    return {
      data: [],
      columns,
      showDrawer: false,
      cardData: {},
      loading: true,
    };
  },
  components: {
    UkTable,
    Drawer,
    EditObject,
  },
  methods: {
    addObject: function () {
      this.cardData = {};
      this.showDrawer = true;
    },
    closeDrawer: function () {
      this.showDrawer = false;
      this.cardData = {};
    },
  },
  computed: {
    computedData: function () {
      function addEmitters(data) {
        data.forEach((item) => {
          item.edit = () => bus.$emit("showDrawer ListOfObjects", item);
        });
      }
      addEmitters(this.data);
      return cloneDeep(this.data);
    },
  },
  created() {
    bus.$on("showDrawer ListOfObjects", (cardData) => {
      this.showDrawer = true;
      this.cardData = {
        ...cardData,
        restaurant: cardData.restaurant.name,
      };
    });
    bus.$on("ListOfObjects update after save", (newData) => {
      const mappedData = mappingB2F(newData);
      const item = this.data.find((item) => item.key === mappedData.key);
      if (item) Object.assign(item, mappedData);
      bus.$emit("UkTable stay at page");
      this.closeDrawer();
    });
    bus.$on("ListOfObjects push and go to the last page", (newData) => {
      this.data.push(mappingB2F(newData));
      bus.$emit("UkTable go to last page");
      this.closeDrawer();
    });
    bus.$on("ListOfObjects update after delete", (newData) => {
      const mappedData = mappingB2F(newData);
      const indexToDelete = this.data.findIndex(
        (item) => item.key === mappedData.key
      );
      this.data.splice(indexToDelete, 1);
      if (indexToDelete === this.data.length) {
        bus.$emit("UkTable go to last page");
      } else {
        bus.$emit("UkTable stay at page");
      }
      this.closeDrawer();
    });
  },
  mounted: function () {
    getPoints((response) => {
      this.data = response.data.data.map(mappingB2F);
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.customTitle {
  color: #1890f0;
}

.component-header {
  display: flex;
  align-items: left;
}

.uk-table-slot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.5);
}

.uk-table-slot a {
  text-decoration: underline;
}
</style>
